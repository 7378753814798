<template>
  <div>
    <div class="agGrid-page container">
      <base-header>
        <show-archived-addon />
      </base-header>
      <div v-html="customHtml"></div>
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :cellValueChanged="cellValueChanged"
        :pinnedTopRowDataCondition="pinnedTopRowDataCondition"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'

export default {
  components: {
    BaseHeader,
    ShowArchivedAddon,
    BaseAgGrid
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      customHtml: null,
      cellValueChanged: null,
      pinnedTopRowDataCondition: null,
      request: null
    }
  },
  props: {
    activityTagName: null,
    projectTypes: null
  },
  async mounted() {
    const yearColumns = []
    const currentYear = new Date().getFullYear()
    const months = SimpleHelper.monthsLabels
    const startYear = 2021
    let years = [this.requestYear ? this.requestYear : currentYear]
    years = years = Array.from({ length: currentYear - startYear + 1}, (_, index) => startYear + index).map(Number)
    for (const year of years) {
      for (let month = 0; month <= 11; month++) {
        const yearMonth = month + '_' + year
        yearColumns.push({
          field: yearMonth,
          headerName: months[month] + ' ' + year,
          editable: true,
          filter: false,
          valueFormatter: (params) => {
            return SimpleHelper.priceFormatter(params.value)
          },
          cellClassRules: {
            'active-project-month': (params) => {
              const month = params.colDef.field
              const active_months = params.data.active_months
              if (month && active_months) {
                const index = active_months.findIndex((a) => a === month)
                if (index !== -1) {
                  return true
                }
              }
              return false
            }
          }
        })
      }
    }
    this.columnDefs = [
      {
        field: 'name',
        headerName: 'Name',
        pinned: 'left',
        cellRenderer: function (params) {
          if (params.node.rowPinned) {
            return params.value
          } else {
            if (!params.value) return params.data._id
            const status = params.data.status
            let intClass = ' '
            if (params.value.includes('-INT')) {
              intClass = ' project_int '
            }
            return `<div class="project_status${intClass}project_status--${status}">${params.value}</div>`
          }
        }
      },
      ...yearColumns
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['showArchived'] = this.$store.getters.getShowArchived(this.$route.name)
        params.request['activityTagName'] = this.activityTagName
        params.request['projectTypes'] = this.projectTypes
        MtApi.agGridProjectsExpenses(params.request).then((response) => {
          this.request = params.request
          this.pinnedTopRowDataCondition = () => {
            return {
              result: [
                {
                  ...{ name: `Total (${response.count || 0})` },
                  ...(response.total[0] ?? '')
                }
              ]
            }
          }
          this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = (params) => {
      let parseDate = params.colDef.field.split('_')
      let month = +parseDate[0] + 1
      let year = parseDate[1]
      const data = {
        event: 'update_project_expenses',
        field: {
          month,
          year,
          amount: params.value,
          activity_tag_name: this.activityTagName
        }
      }
      MtApi.updateProject(params.data._id, data)
      const updateTotal = setTimeout(() => {
        this.rebuildTotal(params)
        clearTimeout(updateTotal)
      }, 600)
    }
  },
  methods: {
    rebuildTotal(params) {
      MtApi.agGridProjectsExpenses(this.request).then((response) => {
        const pinnedRow = params.api.getPinnedTopRow(0)
        params.api.flashCells({ rowNodes: [pinnedRow] })
        pinnedRow.updateData({
          ...{ name: `Total (${response.count || 0})` },
          ...(response.total[0] ?? '')
        })
      })
    }
  }
}
</script>
